<template>
    <div class="filter-container" @click="onClickFilter">
        <div class="box">
            <!-- <div class="text-wrapper">
                <span class="black-r" v-html="filterGenderText.title"></span>
            </div>
            <div class="text-wrapper">
                <span class="black-r" v-html="filterTypeText.title"></span>
            </div> -->
            <div class="text-wrapper">
                <span class="black-r">필터</span>
            </div>
            <i class="material-icons grey">keyboard_arrow_down</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TodayMeetingFilter',
    // props: ['filterType', 'filterTypes', 'filterGender', 'filterGenders'],
    props: ['filterType', 'filterTypes'],
    data: () => ({}),
    computed: {
        // filterGenderText() {
        //     return this.filterGenders[this.filterGender]
        // },
        filterTypeText() {
            return this.filterTypes[this.filterType]
            // if (this.sortType === 0) {
            //     return '최신순'
            // } else if (this.sortType === 1) {
            //     return '내 집과 가까운순'
            // } else if (this.sortType === 2) {
            //     return '내 직장과 가까운순'
            // } else {
            //     return ''
            // }
        },
    },
    methods: {
        onClickFilter() {
            this.$modal
                .custom({
                    component: 'ModalFilter',
                    options: {
                        title: 'FILTER_GENDER',
                        filterType: this.filterType,
                        filterTypes: this.filterTypes,
                        // filterGender: this.filterGender,
                        // filterGenders: this.filterGenders,
                    },
                })
                .then(idxList => {
                    if (idxList !== null && idxList !== undefined) {
                        this.$emit('selectFilter', idxList)
                        // } else if (idxGender !== null && idxGender !== undefined) {
                        //     this.$emit('selectGender', idxGender)
                    }
                })
        },
    },
}
</script>

<style scoped lang="scss">
.filter-container {
    margin: 0 16px 16px 16px;
    // border: 1px solid $purple-primary;
    // background-color: $purple-primary;
    display: flex;
    // justify-content: flex-end;
    width: fit-content;

    .box {
        padding: 8px 12px 8px 12px;
        height: 30px;
        width: fit-content;
        border-radius: 18px;
        font-size: 13px;
        border: solid 1px $grey-03;
        display: flex;
        align-items: center;

        .text-wrapper {
            .black-r {
                color: black;
            }
        }

        .grey {
            color: $grey-07;
        }
    }
}
.clicked {
    background: $grey-03;
}
</style>
